<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formNota" lazy-validation>
      <v-container fluid>
        <v-widget title="Emissão de Nota Fiscal">
          <div slot="widget-content">
            <v-tabs v-model="abaAtiva">
              <v-tab :key="1">Cabeçalho da Nota</v-tab>
              <v-tab :key="2">Itens da Nota</v-tab>
              <v-tab :key="3">Frete e Transportadores</v-tab>
            </v-tabs>

            <v-tabs-items v-model="abaAtiva">
              <v-divider></v-divider>

              <v-tab-item :key="1" eager>
                <v-widget title="Dados do Cabeçalho da Nota" :temCampoObrigatorio="true" class="mt-4">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <v-select label="Tipo de Operação" v-model="nota.tipo" :items="constantes.TIPOS_NOTA" @change="definirCfops()" required></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-select label="Natureza de Operação" v-model="nota.natureza_operacao" :items="naturezasOperacao" @change="definirCfops()" required></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Série *" v-model="nota.serie" v-mask="'###'" :rules="[() => !!nota.serie || 'Série obrigatória', (v) => (v && v.length === 3) || 'Formato Inválido, ex: 001']" required data-cy="serie"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <campo-data label="Data de Emissão *" v-model="nota.data_emissao"></campo-data>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-select label="Finalidade" :items="constantes.FINALIDADE_NFE" v-model="nota.finalidade"></v-select>
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-select label="Indicador de Presença" :items="constantes.INDICADOR_PRESENCA" v-model="nota.indicador_presenca"></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" v-if="nota.finalidade == '4'">
                        <v-text-field label="Chave da Nota Referenciada" v-model="nota.notaRef" v-mask="'###########################################'"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <buscar-participante v-model="cnpjCpfCliente" @participanteSelecionado="adicionarCliente"></buscar-participante>
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-text-field label="Cliente / Fornecedor" v-model="cliente.razaoSocialNome" :rules="[() => !!cliente.razaoSocialNome || 'Cliente é obrigatório']" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>
              </v-tab-item>

              <v-tab-item :key="2" eager>
                <v-widget title="Lançamento dos Itens da Nota" :temCampoObrigatorio="true" class="mt-4">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <buscar-produto v-model="codigoProduto" ref="codigoProduto" @produtoSelecionado="produtoSelecionado"></buscar-produto>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Descrição" v-model="produto.descricao" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field label="CFOP *" v-model="produto.cfop" @keyup.enter="$refs.quantidadeProduto.focus()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="1">
                        <v-text-field ref="quantidadeProduto" label="Qtd." v-model="quantidade" @focus="onFocus" @input="calcularSubtotal" @keyup.enter="$refs.valorProduto.focus()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="1">
                        <v-text-field label="Valor" ref="valorProduto" v-model="produto.valor" @focus="onFocus" @input="calcularSubtotal" @keyup.enter="adicionarProduto()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field label="Total" ref="subtotalProduto" v-model="subtotal" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>

                <v-widget title="Itens da Nota" class="mt-4">
                  <div slot="widget-content">
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-data-table :headers="headers" :items="produtosNota" hide-default-footer no-data-text="Nenhum Produto Adicionado">
                          <template v-slot:item="props">
                            <tr>
                              <td>{{ props.item.codigo }}</td>
                              <td>{{ props.item.sequencia }}</td>
                              <td>{{ props.item.descricao }}</td>
                              <td class="text-right">{{ props.item.valor }}</td>
                              <td class="text-right">{{ props.item.quantidade }}</td>
                              <td class="text-right">{{ props.item.subtotal }}</td>
                              <td class="text-center">
                                <v-icon @click="detalharProduto(props.item.sequencia - 1)">edit</v-icon>
                                <v-icon @click="removerProdutoNota(props.item)">delete</v-icon>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:footer>
                            <!-- <div> -->
                            <v-row dense class="mt-4" justify="end">
                              <v-col cols="12" sm="2">
                                <v-text-field label="Total de Itens" v-model="totalItens" readonly></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-text-field label="Total de Produtos" v-model="totalProdutos" readonly></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-text-field label="Total da Venda" v-model="totalVenda" readonly></v-text-field>
                              </v-col>
                            </v-row>
                            <!-- </div> -->
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>
              </v-tab-item>

              <v-tab-item :key="3" eager>
                <v-widget title="Totais" class="mt-4">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Desconto" v-model="nota.desconto" :rules="validarValor" @blur="recalcularNota()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Frete" v-model="nota.frete" :rules="validarValor" @blur="recalcularNota()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Seguro" v-model="nota.seguro" :rules="validarValor" @blur="recalcularNota()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Outras Despesas" v-model="nota.outras_despesas" :rules="validarValor" @blur="recalcularNota()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Total Produtos" v-model="nota.total_produtos" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Total Tributos" v-model="nota.total_tributos" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Total da Nota" v-model="nota.total_nota" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-textarea label="Informações Complementares" v-model="nota.info_complementar"></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-textarea label="Dados Adicionais" v-model="nota.dados_adicionais"></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>

                <v-widget title="Transportador" class="mt-4" v-if="ehPermitidoTransportador">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <v-select label="Tipo de Frete" :items="constantes.MODELO_FRETE" v-model="nota.modelo_frete"></v-select>
                      </v-col>
                      <v-col cols="12" sm="3" v-if="precisaInformarTransportador">
                        <buscar-transportador v-model="cnpjCpfTransportador" @transportadorSelecionado="transportadorSelecionado"></buscar-transportador>
                      </v-col>
                      <v-col cols="12" sm="6" v-if="precisaInformarTransportador && transportador">
                        <v-select label="Veículos" :items="veiculosTransportador" v-model="veiculoSelecionado" @change="selecionarVeiculo(veiculoSelecionado)"></v-select>
                      </v-col>
                    </v-row>

                    <v-row dense v-if="precisaInformarTransportador && transportador">
                      <v-col cols="12" sm="3">
                        <v-text-field label="CNPJ / CFP" v-mask="'##.###.###/####-##'" v-model="transportador.cnpjCpf" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-text-field label="Razão Social / Nome" v-model="transportador.razaoSocialNome" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Inscrição / RG" v-model="transportador.inscricao_rg" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Código ANT" v-model="transportador.codigo_ant" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field label="Logradouro" v-model="transportador.logradouro" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Cidade" v-model="transportador.cidade" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Estado" v-model="transportador.estado" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Placa" v-model="transportador.placa" :rules="[() => !!transportador.placa || 'Selecione o veículo']" required v-mask="'AAA-###'" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="UF" v-model="transportador.uf" :rules="[() => !!transportador.uf || 'Selecione o veículo']" required readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>

                <v-widget title="Volumes" v-if="precisaInformarTransportador && transportador" class="mt-4">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Quantidade" v-model="transportador.quantidade"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Espécie" v-model="transportador.especie"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Marca" v-model="transportador.marca"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Numeração" v-model="transportador.numeracao"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Peso Bruto" v-model="transportador.peso_bruto"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Peso Líquido" v-model="transportador.peso_liquido"></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>

                <v-widget title="Forma de Pagamento" class="mt-4">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <v-select label="Forma de Pagamento" :items="constantes.FORMA_PAGAMENTO" v-model="nota.forma_pagamento"></v-select>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="nota.forma_pagamento === '1'">
                        <v-combobox label="Prazo (em Dias)" v-model="prazo" :items="constantes.PRAZO"></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="3" v-if="nota.forma_pagamento === '1'">
                        <v-combobox label="Parcelas" v-model="parcelas" :items="constantes.PARCELAS"></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="1" v-if="nota.forma_pagamento === '1'">
                        <v-btn color="primary" :disabled="!podeGerarParcelas" @click="gerarParcelas()"><v-icon>av_timer</v-icon></v-btn>
                      </v-col>
                      <v-col cols="12" sm="12" v-if="nota.forma_pagamento === '1'">
                        <v-data-table :headers="headerParcelasPagamento" :items="parcelasNota" hide-default-footer>
                          <template v-slot:item="props">
                            <tr>
                              <td>{{ props.item.parcela }}</td>
                              <td>{{ props.item.dias }}</td>
                              <td>{{ props.item.vencimento }}</td>
                              <td>{{ props.item.valor }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn color="primary" :loading="carregando" @click="salvar()" :disabled="!valid" data-cy="salvar">{{ textoBtnConfirmar }}</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-container>
    </v-form>

    <dialog-detalhar-produto-nota ref="dialogDetalharProduto" @aplicarCfop="aplicarCfop" @aplicarCsosn="aplicarCsosn" @aplicarAliqIcms="aplicarAliqIcms"></dialog-detalhar-produto-nota>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import formatador from '@/util/formatador';
import conversor from '@/util/conversor';
import DialogBuscarParticipante from '@/components/dialogs/DialogBuscarParticipante';
import DialogDetalharProdutoNota from './DialogDetalharProdutoNota';

export default {
  components: {
    DialogBuscarParticipante,
    DialogDetalharProdutoNota,
  },

  data: () => ({
    abaAtiva: 0,
    valid: false,
    validarValor: [(v) => (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || 'Valor Inválido'],
    constantes: constantes,
    formatador: formatador,
    passo: 1,
    codigoProduto: '',
    cnpjCpfCliente: '',
    cnpjCpfTransportador: '',
    veiculosTransportador: [],
    veiculoSelecionado: {},
    exibirDialogBuscaParticipante: false,
    headers: [
      { text: 'Código', value: 'codigo', sortable: false },
      { text: 'Seq.', value: 'sequencia', sortable: false },
      { text: 'Descrição', value: 'descricao', sortable: false },
      { text: 'Valor', value: 'valor', align: 'right', sortable: false },
      { text: 'Quantidade', value: 'quantidade', align: 'right', sortable: false },
      { text: 'SubTotal', value: 'subtotal', align: 'right', sortable: false },
      { text: 'Remover', value: 'name', align: 'center', sortable: false },
    ],
    headerParcelasPagamento: [
      { text: 'Parcela', value: 'parcela' },
      { text: 'Dias', value: 'dias' },
      { text: 'Vencimento', value: 'vencimento' },
      { text: 'Valor', value: 'valor' },
    ],
    quantidade: '',
    subtotal: '',
    prazo: '',
    parcelas: '',
    cfopPadrao: '',
    loading: false,
  }),

  computed: {
    ...mapState('nota', {
      empresa: 'empresa',
      nota: 'notaAtual',
      cliente: 'clienteAtual',
      produto: 'produtoAtual',
      produtosNota: 'produtosNota',
      parcelasNota: 'parcelasNota',
      transportador: 'transportador',
      carregando: 'carregando',
      cfops: 'cfops',
    }),

    ...mapGetters('nota', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
      totalVenda: 'totalVenda',
      totalProdutos: 'totalProdutos',
      totalItens: 'totalItens',
    }),

    precisaInformarTransportador() {
      let nota = this.$store.state.nota.notaAtual;
      if (nota) {
        if (nota.modelo_frete !== '9') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    ehPermitidoTransportador() {
      let ehPermitido = true;
      if (this.cliente && this.cliente.estado !== this.empresa.estado) {
        ehPermitido = false;
      }
      return ehPermitido;
    },

    podeGerarParcelas() {
      if (this.prazo && this.parcelas && this.prazo !== '' && this.parcelas !== '') {
        return true;
      } else {
        return false;
      }
    },

    naturezasOperacao() {
      const nota = this.$store.state.nota.notaAtual;
      if (nota.tipo == 0) {
        return constantes.NATUREZAS_ENTRADA;
      } else if (nota.tipo == 1) {
        return constantes.NATUREZAS_SAIDA;
      }
      return constantes.NATUREZAS_SAIDA;
    },

    ehSimplesNacional() {
      const configTrib = this.$store.state.nota.empresa.configuracao_tributaria;
      if (configTrib) {
        if (configTrib.regime_tributario == 1 || configTrib.regime_tributario == 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions('nota', {
      carregarEmpresa: 'carregarEmpresa',
    }),

    ...mapMutations('nota', {
      selecionarVeiculo: 'setVeiculo',
      aplicarCfop: 'setCfopTodosProdutosNota',
      aplicarCsosn: 'setCSOSNTodosProdutosNota',
      aplicarAliqIcms: 'setAliqIcmsTodosProdutosNota',
    }),

    removerProdutoNota(item) {
      this.$root.$confirmacao.open('Remover', 'Remover item da nota?').then((confirmado) => {
        if (confirmado) {
          this.$store.commit('nota/removerVendido', item);
        }
      });
    },

    detalharProduto(index) {
      this.$refs.dialogDetalharProduto.abrir(this.produtosNota, index);
    },

    produtoSelecionado(produto) {
      this.codigoProduto = produto.codigo;
      produto.cfop = this.cfopPadrao;
      this.$store.commit('nota/setProduto', produto);
      this.quantidade = 1;
      this.subtotal = formatador.formatarValor(produto.valor);

      setTimeout(() => {
        let quantidadeProduto = this.$refs.quantidadeProduto;
        quantidadeProduto.focus();
      }, 300);
    },

    transportadorSelecionado(transportador) {
      this.cnpjCpfTransportador = transportador.cnpjCpf;
      this.veiculosTransportador = transportador.veiculos.map((veiculo) => {
        return { text: `${veiculo.placa} ${veiculo.uf}`, value: veiculo };
      });
      this.$store.commit('nota/setTransportador', transportador);
    },

    onFocus(e) {
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length;
    },

    adicionarCliente(cliente) {
      this.exibirDialogBuscaParticipante = false;
      this.cnpjCpfCliente = formatador.formatarCnpj(cliente.cnpjCpf);
      let configTrib = this.$store.state.nota.empresa.configuracao_tributaria;
      let cfopConfigurado = configTrib.cfop_padrao;

      this.$store.commit('nota/setCliente', cliente);

      let inicioCnpjCliente = cliente.cnpjCpf.substr(0, 8);
      let inicioCnpjEmitente = this.empresa.cnpj.substr(0, 8);
      if (inicioCnpjCliente === inicioCnpjEmitente) {
        this.$store.commit('nota/setNaturezaOperacao', 'TRANSFERENCIA');
        this.cfopPadrao = '5905';
      } else {
        if (this.empresa.estado === cliente.estado) {
          if (this.nota.tipo === '0') {
            this.cfopPadrao = '1' + cfopConfigurado.substr(1, 3);
          } else if (this.nota.tipo === '1') {
            this.cfopPadrao = '5' + cfopConfigurado.substr(1, 3);
          }
        } else {
          if (this.nota.tipo === '0') {
            this.cfopPadrao = '2' + cfopConfigurado.substr(1, 3);
          } else if (this.nota.tipo === '1') {
            this.cfopPadrao = '6' + cfopConfigurado.substr(1, 3);
          }
        }
      }
    },

    adicionarProduto() {
      let produto = this.$store.state.nota.produtoAtual;
      if (produto.codigo !== '' && this.quantidade !== '' && Number(this.quantidade) > 0 && produto.cfop && produto.cfop !== '') {
        this.$store.dispatch('nota/adicionarProduto', this.quantidade);
      } else {
        this.$store.commit('setMensagemErro', 'Produto Inconsistente');
      }

      this.codigoProduto = '';
      this.quantidade = '';
      this.subtotal = '';
      this.$store.commit('nota/resetProduto');
      this.$refs.codigoProduto.setFocus();
    },

    async salvar() {
      if (this.$refs.formNota.validate()) {
        try {
          let produtos = this.$store.state.nota.produtosNota;
          if (produtos.length > 0) {
            await this.$store.dispatch('nota/salvar');
            this.$router.push('/notas');
          } else {
            this.$store.commit('setMensagemErro', 'Nenhum item adicionado à nota');
          }
        } catch (erro) {
          this.$store.commit('setMensagemErro', erro.message);
        }
      }
    },

    cancelar() {
      this.$router.push('/notas');
    },

    definirCfops() {
      let nota = this.$store.state.nota.notaAtual;
      let cliente = null;
      if (nota.tipo !== '' && nota.natureza_operacao !== '') {
        let cfop = '';
        if (nota.tipo == '0') {
          if (nota.natureza_operacao === 'COMPRA') {
            cfop = '';
          } else if (nota.natureza_operacao === 'CONSIGNACAO') {
            cfop = '';
          } else if (nota.natureza_operacao === 'DEVOLUCAO') {
            this.$store.commit('setFinalidade', 4);
            cfop = '';
          } else if (nota.natureza_operacao === 'REMESSA') {
            cfop = '';
          } else if (nota.natureza_operacao === 'TRANSFERENCIA') {
            cfop = '';
          } else {
            cfop = '';
          }
        } else if (nota.tipo == '1') {
          if (nota.natureza_operacao === 'VENDA') {
            cfop = '';
          } else if (nota.natureza_operacao === 'CONSIGNACAO') {
            cfop = '';
          } else if (nota.natureza_operacao === 'DEVOLUCAO') {
            this.$store.commit('setFinalidade', 4);
            cfop = '';
          } else if (nota.natureza_operacao === 'REMESSA') {
            cfop = '';
          } else if (nota.natureza_operacao === 'TRANSFERENCIA') {
            cfop = '';
          } else {
            cfop = '';
          }
        } else {
          cfop = '';
        }
      }
    },

    gerarParcelas() {
      this.$store.commit('nota/setParcelas', { prazo: this.prazo, parcelas: this.parcelas });
    },

    calcularSubtotal() {
      let produto = this.$store.state.nota.produtoAtual;
      if (produto && this.quantidade !== '') {
        let qtd = conversor.stringParaNumero(this.quantidade);
        let valorProduto = conversor.stringParaNumero(produto.valor);
        this.subtotal = formatador.formatarValor(valorProduto * qtd);
      } else {
        this.subtotal = 0;
      }
    },

    recalcularNota() {
      const nota = this.$store.state.nota.notaAtual;
      if (nota.desconto.match('^[0-9]+(\,[0-9]{1,2})?$') && nota.frete.match('^[0-9]+(\,[0-9]{1,2})?$') && nota.seguro.match('^[0-9]+(\,[0-9]{1,2})?$') && nota.outras_despesas.match('^[0-9]+(\,[0-9]{1,2})?$')) {
        this.$store.commit('nota/setTotais');
      }
    },
  },

  async created() {
    try {
      this.loading = false;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
      if (estaEditando && (!this.nota._id || this.nota._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione uma nota para editar');
        this.$router.push('/notas');
      } else {
        await this.carregarEmpresa();
        if (estaEditando) {
          const cliente = this.$store.state.nota.clienteAtual;
          this.adicionarCliente(cliente);
        }
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
