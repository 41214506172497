<template>
  <v-dialog v-model="dialog" max-width="800px" max-heigth="700px" @keydown.esc="fechar" persistent>
    <v-form v-model="valid" ref="formDetalharProdutoNota" lazy-validation>
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title class="white--text">Detalhamento do Produto</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field dense hide-details label="Código *" v-model="produto.codigo" readonly disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field dense hide-details label="Descrição *" v-model="produto.descricao" readonly disabled></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card flat>
            <v-tabs v-model="abaAtiva" outlined>
              <v-tab :key="1">Informações Gerais</v-tab>
              <v-tab :key="2">Impostos</v-tab>

              <v-spacer></v-spacer>
              <v-btn-toggle class="mr-2" style="margin-top: 10px">
                <v-btn color="primary" small :disabled="index === 0" @click="irParaPrimeiro"><v-icon small>mdi-arrow-collapse-left</v-icon></v-btn>
                <v-btn color="primary" small :disabled="index === 0" @click="irParaAnterior"><v-icon small>mdi-arrow-left</v-icon></v-btn>
                <v-btn color="primary" small :disabled="index === produtos.length - 1" @click="irParaProximo"><v-icon small>mdi-arrow-right</v-icon></v-btn>
                <v-btn color="primary" small :disabled="index === produtos.length - 1" @click="irParaUltimo"><v-icon small>mdi-arrow-collapse-right</v-icon></v-btn>
              </v-btn-toggle>
            </v-tabs>

            <v-tabs-items v-model="abaAtiva">
              <v-divider></v-divider>
              <v-tab-item :key="1">
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="3">
                      <v-text-field dense label="Código Barras" v-model="produto.codigo_barra"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select dense label="Unidade *" v-model="produto.unidade" :items="constantes.UNIDADES" :rules="validarUnidade"></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field dense label="NCM *" v-model="produto.ncm" v-mask="'########'" :rules="validarNcm"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field dense label="CEST" v-model="produto.cest" v-mask="'#######'" :rules="validarCest"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-textarea label="Informações Adicionais" v-model="produto.info_adicional"></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field dense label="Quant." v-model="produto.quantidade" @blur="calcularSubtotal"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field dense label="Valor" v-model="produto.valor" @blur="calcularSubtotal"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field dense label="SubTotal" v-model="produto.subtotal" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field dense label="Seq." v-model="produto.sequencia" readonly></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="2">
                <v-container fluid>
                  <v-row dense class="mt-4">
                    <v-col cols="12" sm="4">
                      <v-text-field dense label="CFOP *" v-model="produto.cfop" v-mask="'####'">
                        <template v-slot:append-outer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon color="primary" @click="aplicarCfop" v-on="on"><v-icon>playlist_add_check</v-icon></v-btn>
                            </template>
                            <span>Aplicar CFOP a todos os itens da nota</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        dense
                        label="CSOSN"
                        :items="
                          constantes.CSOSN.map((item) => {
                            return { text: item.value, value: item.value };
                          })
                        "
                        v-model="produto.csosn"
                      >
                        <template v-slot:append-outer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon color="primary" @click.stop="aplicarCsosn" v-on="on"><v-icon>playlist_add_check</v-icon></v-btn>
                            </template>
                            <span>Aplicar CSOSN a todos os itens da nota</span>
                          </v-tooltip>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-text-field dense label="Alíq. ICMS *" v-model="produto.aliq_icms" suffix="%">
                        <template v-slot:append-outer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon color="primary" @click.stop="aplicarAliqIcms" v-on="on"><v-icon>playlist_add_check</v-icon></v-btn>
                            </template>
                            <span>Aplicar Alíq. ICMS a todos os itens da nota</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-divider></v-divider>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>Impostos</th>
                              <th>Base de Cálculo</th>
                              <th>Alíquota %</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>ICMS</td>
                              <td><input type="text" v-model="produto.bc_icms" @focus="onFocus" @keypress.enter="$refs.detAliqIcms.focus()" @blur="calcularImpostos" /></td>
                              <td><input type="text" ref="detAliqIcms" v-model="produto.aliq_icms" @focus="onFocus" @keypress.enter="$refs.detBcIcmsSt.focus()" @blur="calcularImpostos" /></td>
                              <td><input type="text" v-model="produto.valor_icms" readonly /></td>
                            </tr>
                            <tr>
                              <td>ICMS ST</td>
                              <td><input type="text" ref="detBcIcmsSt" v-model="produto.bc_icms_st" @focus="onFocus" @keypress.enter="$refs.detAliqIcmsSt.focus()" @blur="calcularImpostos" /></td>
                              <td><input type="text" ref="detAliqIcmsSt" v-model="produto.aliq_icms_st" @focus="onFocus" @keypress.enter="$refs.detBcIi.focus()" @blur="calcularImpostos" /></td>
                              <td><input type="text" v-model="produto.valor_icms_st" readonly /></td>
                            </tr>
                            <tr>
                              <td>II</td>
                              <td><input type="text" ref="detBcIi" v-model="produto.bc_ii" @focus="onFocus" @keypress.enter="$refs.detAliqIi.focus()" @blur="calcularImpostos" /></td>
                              <td><input type="text" ref="detAliqIi" v-model="produto.aliq_ii" @focus="onFocus" @keypress.enter="$refs.detBcIpi.focus()" @blur="calcularImpostos" /></td>
                              <td><input type="text" v-model="produto.valor_ii" readonly /></td>
                            </tr>
                            <tr>
                              <td>IPI</td>
                              <td><input type="text" ref="detBcIpi" v-model="produto.bc_ipi" @focus="onFocus" @keypress.enter="$refs.detAliqIpi.focus()" @blur="calcularImpostos" /></td>
                              <td><input type="text" ref="detAliqIpi" v-model="produto.aliq_ipi" @focus="onFocus" @blur="calcularImpostos" /></td>
                              <td><input type="text" v-model="produto.valor_ipi" readonly /></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed class="mr-2" @click="fechar()" :disabled="loading">Cancelar</v-btn>
          <v-btn depressed color="primary" @click="salvar" :loading="loading">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';

export default {
  data: () => ({
    abaAtiva: 0,
    dialog: false,
    valid: false,
    validarUnidade: [(v) => !!v || 'Unidade de Medida Obrigatória'],
    validarValor: [(v) => (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || 'Valor Inválido'],
    validarNcm: [(v) => !!v || 'NCM Obrigatório'],
    validarCest: [(v) => !!v || 'CEST Obrigatório'],
    validarCsosn: [(v) => !!v || 'CSOSN Obrigatório'],
    produto: {
      codigo: '',
      descricao: '',
      codigo_barra: '',
      unidade: '',
      ncm: '',
      info_adicional: '',
    },
    produtos: [],
    index: 0,
    loading: false,
    constantes: constantes,
  }),

  methods: {
    onFocus(e) {
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length;
    },

    abrir(produtos, index) {
      this.index = index;
      this.produtos = produtos;
      this.produto = produtos[this.index];
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
    },

    irParaPrimeiro() {
      this.salvar();
      this.index = 0;
      this.produto = this.produtos[this.index];
    },

    irParaAnterior() {
      this.salvar();
      if (this.index > 0) {
        this.index--;
        this.produto = this.produtos[this.index];
      } else {
        this.$store.commit('setMensagemErro', 'Este é o primeiro item');
      }
    },

    irParaProximo() {
      this.salvar();
      if (this.index < this.produtos.length - 1) {
        this.index++;
        this.produto = this.produtos[this.index];
      } else {
        this.$store.commit('setMensagemErro', 'Este é o último item');
      }
    },

    irParaUltimo() {
      this.salvar();
      this.index = this.produtos.length - 1;
      this.produto = this.produtos[this.index];
    },

    aplicarCfop() {
      let cfop = this.produto.cfop;
      if (cfop && cfop.length === 4) {
        this.$root.$confirmacao.open('Aplicar a Todos', `Aplicar CFOP ${cfop} a todos os itens da nota?`).then((confirmado) => {
          if (confirmado) {
            this.$emit('aplicarCfop', cfop);
            this.$store.commit('setMensagemSucesso', 'CFOP aplicado com sucesso');
          }
        });
      } else {
        this.$store.commit('setMensagemErro', 'CFOP em formato inválido');
      }
    },

    aplicarCsosn() {
      let csosn = this.produto.csosn;
      if (csosn && csosn.length === 3) {
        this.$root.$confirmacao.open('Aplicar a Todos', `Aplicar CSOSN ${csosn} a todos os itens da nota?`).then((confirmado) => {
          if (confirmado) {
            this.$emit('aplicarCsosn', csosn);
            this.$store.commit('setMensagemSucesso', 'CSOSN aplicado com sucesso');
          }
        });
      } else {
        this.$store.commit('setMensagemErro', 'CSOSN em formato inválido');
      }
    },

    aplicarAliqIcms() {
      let aliq = this.produto.aliq_icms;
      let numAliq = conversor.stringParaNumero(aliq);
      if (numAliq >= 0) {
        this.$root.$confirmacao.open('Aplicar a Todos', `Aplicar Alíq. ICMS ${aliq}% a todos os itens da nota?`).then((confirmado) => {
          if (confirmado) {
            this.$emit('aplicarAliqIcms', aliq);
            this.$store.commit('setMensagemSucesso', 'Alíquota aplicada com sucesso');
          }
        });
      } else {
        this.$store.commit('setMensagemErro', 'Alíquota em formato inválido');
      }
    },

    calcularSubtotal() {
      let valor = conversor.stringParaNumero(this.produto.valor);
      let quantidade = conversor.stringParaNumero(this.produto.quantidade);
      let subtotal = valor * quantidade;

      this.produto.subtotal = formatador.formatarValor(subtotal);
      this.produto.bc_icms = subtotal;
    },

    calcularImpostos() {
      //icms
      let bc_icms = conversor.stringParaNumero(this.produto.bc_icms);
      let aliq_icms = conversor.stringParaNumero(this.produto.aliq_icms);

      this.produto.bc_icms = formatador.formatarValor(bc_icms);
      this.produto.aliq_icms = formatador.formatarValor(aliq_icms);
      this.produto.valor_icms = formatador.formatarValor((aliq_icms * bc_icms) / 100);

      //icms st
      let bc_icms_st = conversor.stringParaNumero(this.produto.bc_icms_st);
      let aliq_icms_st = conversor.stringParaNumero(this.produto.aliq_icms_st);

      this.produto.bc_icms_st = formatador.formatarValor(bc_icms_st);
      this.produto.aliq_icms_st = formatador.formatarValor(aliq_icms_st);
      this.produto.valor_icms_st = formatador.formatarValor((aliq_icms_st * bc_icms_st) / 100);

      //ii
      let bc_ii = conversor.stringParaNumero(this.produto.bc_ii);
      let aliq_ii = conversor.stringParaNumero(this.produto.aliq_ii);

      this.produto.bc_ii = formatador.formatarValor(this.produto.bc_ii);
      this.produto.aliq_ii = formatador.formatarValor(this.produto.aliq_ii);
      this.produto.valor_ii = formatador.formatarValor((aliq_ii * bc_ii) / 100);

      //ipi
      let bc_ipi = conversor.stringParaNumero(this.produto.bc_ipi);
      let aliq_ipi = conversor.stringParaNumero(this.produto.aliq_ipi);

      this.produto.bc_ipi = formatador.formatarValor(this.produto.bc_ipi);
      this.produto.aliq_ipi = formatador.formatarValor(this.produto.aliq_ipi);
      this.produto.valor_ipi = formatador.formatarValor((aliq_ipi * bc_ipi) / 100);
    },

    salvar() {
      this.produto.quantidade = conversor.stringParaNumero(this.produto.quantidade);
      this.$store.dispatch('nota/calcularImpostos', this.produto);
      this.$store.commit('nota/setTotais');
    },
  },
};
</script>
